<template>
	<section class="historiSlider">
		<div class="container">
			<div class="title">
				<h2>{{ d.title }}</h2>
			</div>
			<div class="right">
				<Flickity
					class="yearsSlider"
					ref="yearsSlider"
					:options="yearsSlideryOptions"
				>
					<div
						class="slide"
						:class="{ active: index == selectedIndex }"
						v-for="(item, index) in d.list"
						:key="index"
						@click="onYearClick(index)"
					>
						{{ item.year }}
						<div class="decoLine1"></div>
					</div>
				</Flickity>
				<Flickity
					class="historyInfoSlider"
					ref="historyInfoSlider"
					:options="historyInfoSliderOptions"
				>
					<div
						class="slide"
						v-images-loaded.on.progress="resize"
						v-for="(item, index) in d.list"
						:key="index"
					>
						<img :src="path(item.image)" :alt="path(item.image)" />
						<div class="threeColTxt">
							<h3>{{ item.title }}</h3>
							<div class="threecol">
								<div v-html="item.text1"></div>
								<div v-html="item.text2"></div>
								<div>
									<div v-html="item.text3"></div>
									<router-link v-if="item.link" :to="item.link" class="moreLnk"
										>{{ $t("inDetail") }} <span class="decoLine1"></span
									></router-link>
								</div>
							</div>
						</div>
					</div>
				</Flickity>
				<div class="sliderControlls light">
					<div
						class="toprew"
						@click="$refs.historyInfoSlider.previous(false, false)"
					>
						<span class="ic-icarrow-left"></span>
					</div>
					<div
						class="toright"
						@click="$refs.historyInfoSlider.next(false, false)"
					>
						<span class="ic-icarrow-right"></span>
					</div>
					<div class="counter">
						<div>
							{{ selectedIndex + 1 }} / <span> {{ d.list.length }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import imagesLoaded from "vue-images-loaded";
import Flickity from "vue-flickity";
export default {
	name: "HistorySlider",
	directives: {
		imagesLoaded,
	},
	components: {
		Flickity,
	},
	data() {
		return {
			yearsSlideryOptions: {
				prevNextButtons: false,
				// cellAlign: "left",
				contain: true,
				pageDots: false,
				// groupCells: true,
				// asNavFor: ".historyInfoSlider",
			},
			historyInfoSliderOptions: {
				// cellAlign: 'left',
				contain: true,
				prevNextButtons: false,
				pageDots: false,
				adaptiveHeight: true,
			},

			slides: [
				{
					year: "XIX ст.",
				},
				{
					year: "1895 р.",
				},
				{
					year: "1896 р.",
				},
				{
					year: "20-30 рр.",
				},
				{
					year: "39-45 рр.",
				},
				{
					year: "XIX ст.",
				},
				{
					year: "1895 р.",
				},
				{
					year: "1896 р.",
				},
				{
					year: "20-30 рр.",
				},
				{
					year: "39-45 рр.",
				},
			],
			selectedIndex: 0,
		};
	},
	methods: {
		resize() {
			this.$refs.historyInfoSlider.resize();
		},
		onYearClick(i) {
			this.selectedIndex = i;
			this.$refs.historyInfoSlider.select(this.selectedIndex, true, false);
		},
	},
	mounted() {
		this.$refs.historyInfoSlider.on("change", (i) => {
			this.selectedIndex = i;
			this.$refs.yearsSlider.select(this.selectedIndex, true, false);
		});
		this.$refs.yearsSlider.on("change", (i) => {
			// this.selectedIndex = i;
			// this.$refs.historyInfoSlider.select( this.selectedIndex, true, false );
		});
	},
};
</script>

<style lang='scss'>
.historiSlider {
	padding: 60px 0;

	.container {
		@include container;
		display: grid;
		grid-template: 1fr / 25% 1fr;

		@media (max-width: $tl) {
			display: block;
		}

		.title {
			// width: 25%;
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h2;
				margin: 0;
			}
		}

		.right {
			// width: 75%;
			@media (max-width: $tl) {
				margin-top: 30px;
			}

			.yearsSlider {
				width: 100%;
				.flickity-button {
					padding: 0;
					background-color: $cgray7;
					// background-color: red;
					transform: unset;
					border-radius: 0;
					width: 40px;
					height: unset;
					&:disabled {
						opacity: 0;
					}
					.flickity-button-icon {
						width: 15px;
						left: unset;
						right: unset;
						top: unset;
						bottom: unset;
						position: static;
					}
				}
				.previous {
					left: 0;
					bottom: 0;
					top: 0;
				}
				.next {
					right: 0;
					bottom: 0;
					top: 0;
				}
				.slide {
					margin-right: 55px;
					font-style: normal;
					font-weight: normal;
					font-size: 20px;
					line-height: 40px;
					letter-spacing: -0.025em;
					color: #111325;
					position: relative;
					cursor: pointer;
					white-space: nowrap;

					// width: 110px;
					.decoLine1 {
						background-color: transparent;
					}

					&.active {
						.decoLine1::before {
							left: 0;
						}
					}

					&:hover {
						.decoLine1::before {
							left: 0;
						}
					}
				}
			}

			.historyInfoSlider {
				width: 100%;
				margin-top: 18px;

				.flickity-viewport {
					transition: height 0.2s;
				}

				.slide {
					margin-right: 30px;
					width: 100%;

					img {
						width: 100%;
					}

					.threeColTxt {
						margin-top: 16px;
						column-count: 3;
						column-gap: 30px;

						@media (max-width: $tm) {
							column-count: 2;
						}

						@media (max-width: $ts) {
							column-count: 1;
						}
					}

					h3 {
						@include h3;
						margin-top: 0;
					}

					p {
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 130%;
						letter-spacing: 0.01em;
						color: #111325;
					}

					ol {
						list-style: none;
						counter-reset: olcounter;
						padding: 0;
						padding-left: 25px;

						li {
							@include t3l;
							margin: 16px 0;
							position: relative;
							counter-increment: olcounter;

							&::before {
								content: counter(olcounter) ".";
								position: absolute;
								top: 0px;
								left: -22px;
								@include t4;
								color: $cred1;
							}

							& > p {
								margin: 6px 0;

								& + p {
									margin-top: 16px;
								}
							}
						}
					}
					ul {
						list-style: square;

						padding: 0;
						padding-left: 25px;

						li {
							@include t3l;
							margin: 16px 0;
							position: relative;

							&::marker {
								color: $cred1;
							}
						}

						ol > li:before {
							content: counter(olcounter) ".";
							position: absolute;
							top: -1px;
							left: -30px;
							font-style: normal;
							font-weight: 600;
							font-size: 18px;
							color: #000000;
							background-color: transparent;
						}
					}

					q {
						font-style: italic;
						font-weight: 500;
						font-size: 22px;
						line-height: 130%;
						letter-spacing: 0.01em;
						color: #111325;
						margin: 16px 0;
					}
				}
			}

			.sliderControlls {
				margin-top: 30px;
			}
		}
	}
}
</style>