<template>
	<section class="faqSection">
		<div class="container">
			<div class="title">
				<h2>{{ d.title }}</h2>
			</div>
			<accordion mode="singleCaption" :d="d.list"></accordion>
		</div>
	</section>
</template>

<script>
import Accordion from "../Parts/Accordion.vue";
export default {
	components: { Accordion },
	name: "FaqSection",
};
</script>

<style lang="scss">
.faqSection {
	padding: 60px 0;

	.container {
		@include container;
		display: grid;
		grid-template: 1fr / 25% 1fr;

		@media (max-width: $tl) {
			display: block;
		}

		.title {
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h2;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}

		.accordionComponent {
			@media (max-width: $tl) {
				margin-top: 30px;
			}
		}
	}
}
</style>