<template>
	<section class="popularChapters">
		<div class="container">
			<div class="title">
				<h2>{{ d.title }}</h2>
			</div>
			<div class="chaptersWrp">
				<template v-for="(item, index) in d.list">
					<router-link
						v-if="item.link"
						:key="item.link + index"
						:to="{ path: item.link }"
						class="chapterItem"
					>
						<div
							class="ico"
							:style="{
								backgroundImage: `url(${path(item.image)}`,
							}"
						>
							<!-- <img :src="require(`@/assets/img/Union.svg`)" alt="1" /> -->
						</div>
						<div class="caption">{{ item.title }}</div>
						<div class="sub" v-html="item.text">
							<!-- {{ item.text }} -->
						</div>
						<span class="ic-icarrow-right"></span>
					</router-link>
					<div
						v-else
						:key="item.link + index"
						class="chapterItem"
					>
						<div
							class="ico"
							:style="{
								backgroundImage: `url(${path(item.image)}`,
							}"
						>
							<!-- <img :src="require(`@/assets/img/Union.svg`)" alt="1" /> -->
						</div>
						<div class="caption">{{ item.title }}</div>
						<div class="sub" v-html="item.text">
							<!-- {{ item.text }} -->
						</div>
					</div>
				</template>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "PopularChapters",
};
</script>

<style lang="scss">
.popularChapters {
	background: $cgray6;
	padding: 30px 0;

	@media (max-width: $tl) {
		padding: 60px 0;
	}

	.container {
		@include container;
		display: grid;
		grid-template: 1fr / 25% 1fr;

		@media (max-width: $tl) {
			display: block;
		}

		.title {
			// width: 25%;
			padding-right: 30px;

			h2 {
				@include h2;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}

		.chaptersWrp {
			// width: 75%;
			// display: flex;
			// flex-wrap: wrap;
			display: grid;
			grid-template-columns: repeat(3, 33.33%);
			grid-gap: 2px;

			@media (max-width: $tl) {
				margin-top: 50px;
			}

			@media (max-width: $tm) {
				grid-template: 1fr 1fr / 1fr 1fr;
			}

			@media (max-width: $ts) {
				margin-top: 25px;
			}

			.chapterItem {
				background: $cwhite;
				width: unset;
			}
		}
	}
}
</style>