<template>
	<div>
		<label
			:for="`input${_uid}`"
			class="inputComponent"
			:class="{ isError: isError }"
		>
			<template v-if="!mask">
				<input
					:id="`input${_uid}`"
					:type="itype ? itype : 'text'"
					v-model.trim="value"
					@keyup="onKeyUp"
					:placeholder="placeholder"
					:name="propname"
				/>
			</template>
			<template v-else>
				<the-mask
					:id="`input${_uid}`"
					:mask="mask ? mask : ''"
					:masked="true"
					v-model.trim="value"
					@keyup.native="onKeyUp"
				/>
			</template>
			<span class="ic-icarrow-right" :class="btnClass" @click="onBtnClc"></span>
		</label>
		<span class="tip" v-if="isError">{{ isError }}</span>
	</div>
</template>

<script>
import defaultinput from '@/components/Inputs/DefaultInputMixin.js';

export default {
	name: "DefaultInput",
	mixins: [
		defaultinput
	],
};
</script>

<style lang='scss'>
.inputComponent {
	border: 1px solid $cgray3;
	position: relative;
	margin-top: 10px;
	display: block;
	box-sizing: border-box;

	input {
		height: 58px;
		width: 100%;
		border: none;
		background-color: transparent;
		outline: none;
		font-style: normal;
		font-weight: normal;
		font-size: 18px;
		line-height: 130%;
		letter-spacing: 0.01em;
		color: #111325;
		padding: 15px 100px 15px 15px;
		color: $cblacktxt;

		&::placeholder {
			color: $cblacktxt;
		}
	}

	.ic-icarrow-right {
		width: 100px;
		height: 100%;
		font-size: 24px;
		position: absolute;
		top: 0;
		right: 0;
		overflow: hidden;
		pointer-events: none;
		&::before {
			position: absolute;
			top: 50%;
			right: 30px;
			transform: translate(50%, -50%);
			z-index: 10;
			transition: 0.3s ease;
		}
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			left: calc(100% + 1px);
			background-color: $cgraybg1;
			z-index: 5;
			transition: 0.3s ease;
		}
		&.active {
			pointer-events: unset;
			cursor: pointer;
			&::after {
				width: 100%;
				height: 100%;
				left: 0;
			}
			&::before {
				color: $cwhite;
			}
		}
	}
	&.isError{
		border: 2px solid $cred1;
	}
}
.tip{
	@include t3;
	color: $cred1;
}
</style>