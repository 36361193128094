const MEDIA_PATH_PREFIX = '/storage/app/public/media';
const FILE_PATH_PREFIX = '/storage/app/public/files';

let utils = {
    props: ['d'],
    computed: {
        locale() {
            return this.$i18n.locale == "" ? "uk" : this.$i18n.locale
        }
    },
    methods: {
        path(s) {
            if (s) {
                if (process.env.NODE_ENV == 'development')
                    return `https://biopharma.ua${MEDIA_PATH_PREFIX}${s}`;
                else return MEDIA_PATH_PREFIX + s;
            } else return null
        },
        pathFile(s) {
            if (s) {
                if (process.env.NODE_ENV == 'development')
                    return `https://biopharma.ua${FILE_PATH_PREFIX}${s}`;
                else return FILE_PATH_PREFIX + s;
            } else return null
        },
        isExtUrl(s) {
            if (s.includes('http')) return true
            else if (s.includes('@')) return true
            else return false
        },
        linkClick(link, blank) {
            if (this.isExtUrl(link)) {
                if (link.includes('@')) {
                    location.href = link;
                } else {

                    if (blank) {
                        window.open(link, '_blank');
                    } else {
                        window.location.assign(link);
                    }
                }
            } else {
                this.$router.push(link)
            }
        },
        refreshSlider(refname) {
            this.$refs[refname].resize();
        },
        solveStatus(status) {
            if (status == 404) {
                this.$router.push({
                    name: "Page404"
                })
            }
        },

        // Google Tag Manager
        TGMPush(arg) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'page_view',
                'CityName': arg.city || '',
                'pageTitle': arg.title || '',
                'pagePath': this.$route.fullPath,
                'originalLocation': window.location.origin,
            });
        },
        // Input methods
        onFieldChange(e) {
            if (typeof e.name == 'object') {
                if (e.name.array) {
                    this[e.name.array][e.name.index][e.name.name] = e.value;
                }
            } else {
                this[e.name] = e.value;

            }
        },
        onPhoneChange(e) {
            this[e.name] = e.value;
            if (e.lengthname) {
                this[e.lengthname] = e.length;
            } else {
                this.phoneLength = e.length;

            }
        },
        onRadioChange(e) {
            if (typeof e.name == 'object') {
                if (e.name.array) {
                    this[e.name.array][e.name.index][e.name.name] = e.value;
                    if (e.extra) {
                        this[e.name.array][e.name.index][`${e.name.name}Extra`] = e.value;
                    } else {
                        this[e.name.array][e.name.index][e.name.name] = e.value;
                    }
                }
            } else {
                if (e.extra) {
                    this[`${e.name}Extra`] = e.value;
                } else {
                    this[e.name] = e.value;
                    if (!e.haveExtra) {
                        this[`${e.name}Extra`] = '';
                    }
                }

            }
        }
    },
}

export {
    utils
}

export let rtParams = {
    ruLocaleParam() {
        let p = {};
        for (const [key, value] of Object.entries(this.$route.params)) {
            p[key] = value;
        }
        p.locale = 'ru';
        return p;
    },
    uaLocaleParam() {
        let p = {};
        for (const [key, value] of Object.entries(this.$route.params)) {
            p[key] = value;
        }
        p.locale = null;
        return p;
    },
    enLocaleParam() {
        let p = {};
        for (const [key, value] of Object.entries(this.$route.params)) {
            p[key] = value;
        }
        p.locale = 'en';
        return p;
    },
};
