<template>
	<section class="companyInfoAccordion" :class="theme">
		<div class="container">
			<div class="title">
				<h2 :class="headerStyle">{{d.title}}</h2>
			</div>
			<accordion mode="doubleCaption" :d="d.list"></accordion>
		</div>
	</section>
</template>

<script>
import Accordion from "../Parts/Accordion.vue";
export default {
	components: { Accordion },
	name: "CompanyInfoAccordion",
	props: ["headerstyle", 'theme'],
	computed: {
		headerStyle() {
			if (this.headerstyle) {
				if (this.headerstyle == 1) return "s1";
				else if (this.headerstyle == 2) return "s2";
			} else return "s1";
		},
	},
};
</script>

<style lang="scss">
.companyInfoAccordion {
	padding: 60px 0;
	background-color: $cwhite;

	.container {
		@include container;
		display: grid;
		grid-template: 1fr / 25% 1fr;

		@media (max-width: $tl) {
			display: block;
		}

		.title {
			// width: 25%;
			padding-right: 30px;
			flex-shrink: 0;

			.s1 {
				@include h2;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
			.s2 {
				@include h3mob;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}

		.accordionComponent {
			@media (max-width: $tl) {
				margin-top: 30px;
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>