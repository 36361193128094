var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"logoLingWrp"},[_c('router-link',{staticClass:"logoLink",attrs:{"to":{
                    name: 'MainPage',
                    params: {
                        locale: _vm.$i18n.locale == 'uk' ? null : _vm.$i18n.locale
                    }
                }}},[_c('img',{attrs:{"src":require("@/assets/img/logo.svg"),"alt":"logo"}})])],1),_c('div',{staticClass:"hdRightTop"},[_c('router-link',{staticClass:"searchWrp",attrs:{"to":{
                    name: 'search',
                    params: { locale: _vm.locale == 'uk' ? null : _vm.locale }
                }}},[_c('span',{staticClass:"ic-icsearch"})]),_c('combo-box',{attrs:{"items":_vm.langs}}),_c('div',{staticClass:"signWrp"},[_c('a',{staticClass:"button1",attrs:{"href":_vm.$store.state.sing_link,"target":"_blank"}},[_c('span',{staticClass:"t"},[_vm._v(_vm._s(_vm.$store.state.sing_link_title))]),_c('span',{staticClass:"ic-icarrow-right"})])])],1),(_vm.mainMenu)?_c('Flickity',{ref:"menuSlider",staticClass:"hdRightBot",attrs:{"options":_vm.flickityOptions}},[_vm._l((_vm.mainMenu),function(item,idx){return [(!item.children)?_c('router-link',{key:idx,staticClass:"lnk",attrs:{"to":item.url}},[_c('span',[_vm._v(_vm._s(item.name)+" "),_c('span',{staticClass:"decoLine1"})])]):_c('div',{key:idx,staticClass:"dropdown"},[_c('router-link',{staticClass:"lnk",attrs:{"to":item.url},nativeOn:{"mouseenter":function($event){return _vm.onDDenter($event, idx)},"mouseleave":function($event){return _vm.onDDleave(idx)}}},[_c('span',[_vm._v(_vm._s(item.name)+" "),_c('span',{staticClass:"decoLine1"})])]),_c('span',{staticClass:"ic-icexpand-down"})],1)]})],2):_vm._e(),(_vm.ddShown)?_c('div',{staticClass:"dd",style:(_vm.ddPos),on:{"mouseenter":function($event){return _vm.onDDenter($event)},"mouseleave":function($event){return _vm.onDDleave()}}},_vm._l((_vm.curChildren),function(item,index){return _c('router-link',{key:index,attrs:{"to":item.url},nativeOn:{"click":function($event){_vm.ddShown = false}}},[_vm._v(_vm._s(item.name))])}),1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }