<template>
	<div class="mobileMenu">
		<div class="hd">
			<router-link
				:to="{
					name: 'MainPage',
					params: { locale: $i18n.locale == 'uk' ? null : $i18n.locale },
				}"
				class="logoLink"
			>
				<img :src="require(`@/assets/img/logo.svg`)" alt="logo" />
			</router-link>
			<router-link :to="'/search'" class="searchWrp">
				<span class="ic-icsearch"></span>
			</router-link>
		</div>
		<div class="mm">
			<template v-for="(item, idx) in this.$store.state.mainMenu">
				<router-link
					v-if="!item.children"
					:key="idx"
					:to="item.url"
					class="lnk"
					@click.native="$emit('close')"
					><span>{{ item.name }} <span class="decoLine1"></span></span
				></router-link>
				<div v-else :key="idx" class="dropdown">
					<div class="wrp" @click="onScroll(idx)">
						<div
							:to="item.url"
							class="lnk"
							@mouseenter.native="onDDenter($event, idx)"
							@mouseleave.native="onDDleave(idx)"
						>
							<span>{{ item.name }} <span class="decoLine1"></span></span>
						</div>
						<span class="ic-icexpand-down"></span>
					</div>
					<slide-up-down :active="itms[idx]">
						<router-link
							v-for="(item2, idx2) in item.children"
							:key="idx2"
							:to="item2.url"
							class="lnk"
							@click.native="$emit('close')"
							><span
								><span class="ic-bc"></span>
								{{ item2.name }}
								<span class="decoLine1"></span></span
						></router-link>
					</slide-up-down>
				</div>
			</template>
			<a :href="$store.state.sing_link" class="button1" target="_blank">
				<span class="t">{{ $store.state.sing_link_title }}</span>
				<span class="ic-icarrow-right"></span>
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: "MobileMenu",
	data() {
		return {
			itms: [],
		};
	},
	methods: {
		onScroll(i) {
			this.$set(this.itms, i, !this.itms[i]);
		},
	},
	mounted() {
		this.itms = [];
		console.log(this.$store.state.mainMenu);

		this.$store.state.mainMenu.forEach(() => {
			this.itms.push(false);
		});
	},
};
</script>

<style lang="scss" scoped>
.mobileMenu {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 49;
	background-color: $cgray6;

	.hd {
		height: 55px;
		padding-right: 55px;
		padding-left: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		img {
			max-width: 127px;
		}
		.searchWrp {
			height: 15px;
			padding: 0 15px;
			text-decoration: none;
			.ic-icsearch {
				font-size: 19px;
				color: #595b69;
			}
		}
	}
	.mm {
		padding: 0 15px;
		margin-top: 10px;
		.dropdown {
			& + .lnk,
			& + .dropdown {
				border-top: 1px solid $cgray4;
			}
			.wrp {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
		.lnk {
			padding: 13px 0;
			@include t3;
			color: $cgray2;
			display: block;
			text-decoration: none;
			& + .lnk,
			& + .dropdown {
				border-top: 1px solid $cgray4;
			}
			.ic-bc {
				font-size: 18px;
			}
		}
		.button1{
			width: 100%;
		}
	}
}
</style>