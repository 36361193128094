<template>
	<section class="seeAlsoSection">
		<div class="container">
			<div class="title">
				<h2>{{ d.title }}</h2>
			</div>
			<div v-if="!isMobile" class="mm seeAlsoItemsWrp">
				<a v-for="(item, index) in d.list" :key="index" :href="item.link" target="_blank" class="seeAlsoItem">
					<img :src="path(item.image)" :alt="path(item.image)" />
					<div class="caption">{{item.title}}</div>
					<div class="sub" v-html="item.text"></div>
					<span class="ic-icarrow-right"></span>
				</a>
			</div>
			<!-- <Flickity v-if="isMobile" ref="flic" class="mm seeAlsoItemsWrp2" :options="options">
				<div v-for="(item, index) in d.list" :key="index" :href="item.link" target="_blank" class="seeAlsoItem">
					<img :src="path(item.image)" :alt="path(item.image)" />
					<div class="caption">{{item.title}}</div>
					<div class="sub" v-html="item.text"></div>
					<span class="ic-icarrow-right"></span>
				</div>
			</Flickity> -->
			<div v-if="isMobile" class="mm seeAlsoItemsWrp2">
				<a v-for="(item, index) in d.list" :key="index" :href="item.link" target="_blank" class="seeAlsoItem">
					<img :src="path(item.image)" :alt="path(item.image)" />
					<div class="caption">{{item.title}}</div>
					<div class="sub" v-html="item.text"></div>
					<span class="ic-icarrow-right"></span>
				</a>
			</div>
		</div>
	</section>
</template>

<script>
import imagesLoaded from "vue-images-loaded";
import Flickity from "vue-flickity";
export default {
	name: "SeeAlsoSection",
	directives: {
		imagesLoaded,
	},
	components: {
		Flickity,
	},
	data() {
		return {
			options: {
				prevNextButtons: false,
				pageDots: false,
				contain: true,
				cellAlign: "left",
        freeScroll: true,
			},
			isMobile: true,
		};
	},
	methods: {
		onResize() {
			this.isMobile = window.innerWidth > 600 ? false : true;
		},
	},
	created() {
		this.isMobile = window.innerWidth > 600 ? false : true;
		window.addEventListener("resize", this.onResize);
    setTimeout(() => {
      this.$refs.flic.on('dragStart', () => (document.ontouchmove = e => {e.preventDefault()}));
      this.$refs.flic.on('dragEnd', () => (document.ontouchmove = () => true));
    }, 1000);
	},
	destroyed() {
		window.removeEventListener("resize", this.onResize);
	},
};
</script>

<style lang="scss">
.seeAlsoSection {
	padding: 30px 0;

	.container {
		@include container;
		display: grid;
		grid-template: 1fr / 25% 1fr;

		@media (max-width: $tl) {
			display: block;
		}

		.title {
			// width: 25%;
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h2;
				margin: 0;
			}
		}

		.mm {
      &.seeAlsoItemsWrp{
        // width: 75%;
        display: grid;
        grid-template: 1fr / 1fr 1fr 1fr;
        grid-column-gap: 20px;
        @media (max-width: $tl) {
          margin-top: 30px;
        }
        @media (max-width: $ts) {
          display: block;
        }
      }
      &.seeAlsoItemsWrp2{
        width: 100%;
        // display: grid;
        // grid-template: 1fr / 1fr 1fr 1fr;
        // grid-column-gap: 20px;
        display: flex;
        overflow-x: auto;
        @media (max-width: $tl) {
          margin-top: 30px;
        }
        .seeAlsoItem{
          flex: 0 0 300px;
        }
      }
			.seeAlsoItem {
				display: block;
				text-decoration: none;
				position: relative;
				padding-bottom: 35px;

				@media (max-width: 600px) {
					width: 260px;
					margin-right: 20px;
				}

				img {
					width: 100%;
					max-height: 220px;
					object-fit: cover;
				}

				.caption {
					@include h4;
					margin-top: 10px;
					transition: 0.3s ease;
				}

				.sub {
					@include t4;
					margin-top: 10px;
				}

				.ic-icarrow-right {
					position: absolute;
					bottom: 2px;
					left: 2px;
					font-size: 20px;
					color: #000;
					transition: 0.3s ease;
				}

				&:hover {
					.caption {
						color: $cred1;
					}

					.ic-icarrow-right {
						color: $cred1;
					}
				}
			}
		}
	}
}
</style>