<template>
	<footer>
		<div class="container">
			<div class="logoAndMenu">
				<a href="#">
					<img :src="require(`@/assets/img/footerlogo.svg`)" alt="123" />
				</a>
				<nav>
					<template v-if="$store.state.footerMenu">
						<div v-for="(item, index) in $store.state.footerMenu" :key="index">
							<router-link :to="item.url"
								>{{ item.name }}<span class="decoLine1"></span
							></router-link>
						</div>
					</template>
				</nav>
			</div>
			<div class="formAndSoc">
				<div class="footerForm">
					<div v-if="$store.state.footer_form_title" class="caption">
						{{ $store.state.footer_form_title }}
					</div>
					<footer-email></footer-email>
				</div>
				<div class="soc">
					<template v-if="$store.state.links">
						<a
							v-for="(item, index) in $store.state.links"
							:key="index"
							:href="item.link"
							target="_blank"
						>
							<span :class="item.icon"></span>
						</a>
					</template>
				</div>
				<div class="confidentiality">
					<a
						v-if="$store.state.footer_link_text"
						:href="$store.state.footer_link_href"
						>{{ $store.state.footer_link_text }}</a
					>
				</div>
			</div>
		</div>
		<div class="container fbot">
			<div class="footerBot">
				<div class="langWrp">
					<combo-box :items="langs" :classprop="'footerlng'"></combo-box>
				</div>
				<p v-if="$store.state.copyright" class="copiright">
					{{ $store.state.copyright }}
				</p>
				<a href="https://owlweb.com.ua/" target="blank" ref="nofollow" class="created"
					>Created by <span class="ic-owl-logo"></span
				></a>
			</div>
		</div>
	</footer>
</template>

<script>
import FooterEmail from "../Inputs/FooterEmail.vue";
import ComboBox from "./ComboBox.vue";
export default {
	components: { FooterEmail, ComboBox },
	name: "Footer",
	computed: {
		langs() {
			return [
				{ title: "Укр", lang: "uk" },
				{ title: "En", lang: "en" },
				// { title: "Рус", lang: "ru" },
			];
		},
	},
};
</script>

<style lang="scss">
footer {
	background-color: $cgray6;
	padding: 30px 0 0;

	.container {
		@include container;
		&.fbot {
			padding: 0;
		}
		.logoAndMenu {
			display: grid;
			grid-template: 1fr / 25% 1fr;

			@media (max-width: $tm) {
				grid-template: 1fr / 1fr 2fr;
			}

			@media (max-width: $ts) {
				display: block;
			}

			& > a {
				width: 70%;

				@media (max-width: $ts) {
					display: block;
					width: 236px;
				}

				img {
					width: 100%;
				}
			}

			nav {
				display: grid;
				grid-template: 1fr / 1fr 1fr 1fr;
				grid-gap: 10px 20px;

				@media (max-width: $tm) {
					grid-template: 1fr / 1fr 1fr;
				}

				@media (max-width: $ts) {
					display: block;
					margin-top: 30px;
				}

				a {
					@include t4;
					text-decoration: none;
					color: $cgray2;
					position: relative;
					padding-bottom: 4px;

					@media (max-width: $ts) {
						@include t3;
						display: block;
						padding: 0;
						line-height: 50px;
						border-bottom: 1px solid $cgray2;
					}

					.decoLine1 {
						background-color: transparent;

						@media (max-width: $ts) {
							display: none;
						}

						&::before {
							background-color: $cgray3;
						}
					}

					&:hover {
						.decoLine1::before {
							left: 0;
						}
					}
				}
			}
		}

		.formAndSoc {
			margin-top: 60px;
			margin-bottom: 23px;
			display: grid;
			grid-template: 1fr / 2fr 1fr 1fr;
			grid-gap: 15px;

			@media (max-width: $tl) {
				grid-template: auto / 2fr 1fr;
			}

			@media (max-width: $tm) {
				grid-template: auto / auto;
			}

			@media (max-width: $ts) {
				grid-template: auto / 1fr;
				grid-gap: 25px;
			}

			.footerForm {
				.caption {
					@include t3l;
				}

				@media (max-width: $ts) {
					grid-column: span 2;
				}
			}

			.soc {
				display: flex;
				align-items: flex-end;

				@media (max-width: $ts) {
					grid-column: span 2;
				}

				a {
					width: 56px;
					height: 56px;
					margin-right: 20px;
					border-radius: 50%;
					border: 1px solid $cgraybg1;
					display: flex;
					justify-content: center;
					align-items: center;
					text-decoration: none;
					font-size: 20px;
					color: $cgraybg1;
					transition: 0.1s ease;
					&:hover {
						background-color: $cgraybg1;
						border-color: $cgraybg1;
						color: $cwhite;
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}

			.confidentiality {
				display: flex;
				align-items: flex-end;
				@include t3;

				@media (max-width: $tl) {
					grid-column: span 2;
				}

				a {
					height: 56px;
					margin: 0;
					display: flex;
					align-items: center;
					color: $cgray3;
					text-decoration: none;
				}
			}
		}

		.footerBot {
			background-color: $cgraybg1;
			display: grid;
			grid-template: 1fr / 25% 1fr 25%;
			// height: 62px;
			@media (max-width: $ts) {
				grid-template: auto / 2fr 1fr;
			}

			.langWrp {
				border-right: 1px solid #40414f;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 10px 15px;
				@media (max-width: $ts) {
					grid-column: span 2;
					padding: 15px;
					border-right: unset;
					border-bottom: 1px solid #40414f;
				}
			}

			.copiright {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 130%;
				letter-spacing: 0.01em;
				color: rgba(255, 255, 255, 0.65);
				display: flex;
				align-items: center;
				padding-left: 20px;
			}

			.created {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 130%;
				letter-spacing: 0.01em;
				color: rgba(255, 255, 255, 0.65);
				text-decoration: none;
				padding-right: 20px;
				white-space: nowrap;

				.ic-owl-logo {
					margin-left: 8px;
				}
			}
		}
	}
}
</style>