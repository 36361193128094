<template>
  <main>
    <transition name="fade">
      <div v-if="loaded">
        <template v-for="(item, index) in constructor">
          <component
            v-if="item.visibility == '1'"
            :key="item.component + index"
            :is="item.component"
            :d="item.content"
            :data-id="item.widget_id"
            :theme="item.theme || null"
            :textprop="item.component"
          ></component>
        </template>
      </div>
    </transition>
  </main>
</template>

<script>
import AdvantagesSection1 from "../components/Sections/AdvantagesSection1.vue";
import CompanyInfoAccordion from "../components/Sections/CompanyInfoAccordion.vue";
import FaqSection from "../components/Sections/FaqSection.vue";
import FirstScreen from "../components/Sections/FirstScreen.vue";
import FullWidthImgSection from "../components/Sections/FullWidthImgSection.vue";
import HistorySlider from "../components/Sections/HistorySlider.vue";
import InfoSlider from "../components/Sections/InfoSlider.vue";
import PopularChapters from "../components/Sections/PopularChapters.vue";
import SeeAlsoSection from "../components/Sections/SeeAlsoSection.vue";
import TextLeftCol from "../components/Sections/TextLeftCol.vue";
export default {
  components: {
    "main-banner": FirstScreen,
    "sections-often-searched": PopularChapters,
    "what-is-biopharma": FaqSection,
    "video-slider": InfoSlider,
    accordion: AdvantagesSection1,
    "accordion-2": CompanyInfoAccordion,
    "image-and-link": FullWidthImgSection,
    "history-slider": HistorySlider,
    "see-also-main": SeeAlsoSection,
    "text-element": TextLeftCol
  },
  name: "MainPage",
  data() {
    return {
      constructor: null,
      loaded: false
    };
  },
  created() {
    this.axios
      .post("/api/get-main-page", {
        lang: this.locale
      })
      .then(response => {
        this.constructor = response.data.data.constructor;
        document.title =
          response.data.data.translate.meta_title ||
          response.data.data.translate.title;
        this.loaded = true;
      });
  }
};
</script>

<style lang="scss"></style>
