<template>
	<div :class="classprop">
		<div class="wrp" @click="opened = !opened">
			<span class="cur">{{ cur }}</span
			><span class="ic-icexpand-down"></span>
		</div>
		<div v-if="opened" class="list">
			<div
				v-for="(item, index) in items"
				:key="index"
				@click="onClick(item.lang)"
			>
				{{ item.title }}
			</div>
		</div>
	</div>
</template>

<script>
import { Bus } from "@/main.js";
export default {
	name: "ComboBox",
	props: {
		items: {
			type: Array,
			default: [],
		},
		classprop: {
			type: String,
			default: "langSwitch",
		},
	},
	data() {
		return {
			opened: false,
			currentIndex: 0,
		};
	},
	methods: {
		onOutsideClick(e) {
			if (
				e.target.classList.contains == this.classprop ||
				e.target.closest(`.${this.classprop}`)
			) {
				//кликнули по боксу
			} else {
				// кликнули по остальной странице
				this.opened = false;
				window.removeEventListener("click", this.onOutsideClick);
			}
		},
		onClick(lang) {
			this.opened = false;
			Bus.$emit('langchange', lang)
		},
	},
	watch: {
		opened(newValue) {
			if (newValue === true) {
				setTimeout(() => {
					window.addEventListener("click", this.onOutsideClick);
				}, 10);
			}
		},
	},
	computed: {
		cur() {
			let result = "";
			this.items.forEach((item) => {
				if (item.lang == this.$i18n.locale) result = item.title;
			});
			return result;
		},
	},
};
</script>

<style lang="scss" scoped>
.footerlng {
	width: 100%;
	border: 1px solid $cgray2;
	position: relative;
	// display: flex;
	// align-items: center;
	cursor: pointer;
	position: relative;
	.wrp {
		height: 41px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.cur {
		@include t3l;
		color: $cgray7;
		margin-left: 15px;
	}

	.ic-icexpand-down {
		margin-right: 10px;
		font-size: 20px;
		color: $cgray4;
	}
	.list {
		position: absolute;
		left: 0;
		min-width: 80px;
		bottom: calc(100% + 5px);
		background-color: #fff;
		z-index: 10;
		border: 1px solid $cgray5;
		padding: 5px 0;
		div {
			padding: 10px 20px;
			&:hover {
				background-color: $cgray6;
			}
		}
	}
}
.langSwitch {
	padding: 0 15px;
	position: relative;
	min-width: 80px;
	box-sizing: border-box;
	cursor: pointer;
	.wrp {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.cur {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 130%;
		letter-spacing: 0.01em;
		color: #595b69;
	}

	.ic-icexpand-down {
		font-size: 16px;
		color: #595b69;
	}
	.list {
		position: absolute;
		left: 0;
		min-width: 80px;
		top: calc(100% + 5px);
		background-color: #fff;
		z-index: 10;
		border: 1px solid $cgray5;
		padding: 5px 0;
		div {
			padding: 10px 20px;
			&:hover {
				background-color: $cgray6;
			}
		}
	}
}
</style>