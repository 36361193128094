<template>
	<div class="accordionComponent">
		<div
			class="item"
			v-for="(item, index) in d"
			:key="index"
			:class="{ open: openedItems[index] }"
		>
			<div class="accordhead" @click="openClose(index)">
				<div class="caption" v-if="mode == 'singleCaption'">
					{{ item.title }}
				</div>
				<div class="caption2" v-if="mode == 'doubleCaption'">
					<div class="leftCaption">{{ item.title }}</div>
					<div class="rightCaption">{{ item.subtitle }}</div>
				</div>
				<span class="ic-icplus"></span>
			</div>
			<slide-up-down :active="openedItems[index]" class="accordbody">
				<template v-if="type(item) == 'leftText'">
					<div class="leftText">
						<img :src="path(item.image)" :alt="path(item.image)" class="left" />
						<div v-html="item.text"></div>
						<template v-if="item.link">
							<a v-if="isExtUrl(item.link)" :href="item.link" class="moreLnk"
								>{{ $t("inDetail") }} <span class="decoLine1"></span
							></a>
							<router-link v-else :to="item.link" class="moreLnk"
								>{{ $t("inDetail") }} <span class="decoLine1"></span
							></router-link>
						</template>
					</div>
				</template>
				<template v-else>
					<div class="threeCol">
						<div v-html="item.text1"></div>
						<div v-html="item.text2"></div>
						<div v-html="item.text3"></div>
					</div>
				</template>
				<div class="bot"></div>
			</slide-up-down>
		</div>
	</div>
</template>

<script>
export default {
	name: "Accordion",
	props: ["mode"],
	data() {
		return {
			openedItems: [],
		};
	},
	methods: {
		openClose(i) {
			this.$set(this.openedItems, i, !this.openedItems[i]);
		},
		type(item) {
			if (item.text3 != undefined) return "threeCol";
			else return "leftText";
		},
	},
	computed: {},
	mounted() {
		this.d.forEach(() => {
			this.openedItems.push(false);
		});
	},
};
</script>

<style lang="scss">
.accordionComponent {
	.item {
		width: 100%;
		border-bottom: 1px solid #000;

		.accordhead {
			padding: 20px 00px 20px 0;
			position: relative;
			cursor: pointer;
			.caption {
				@include h4;
				padding-right: 25px;
				transition: 0.3s ease;
				@media (max-width: $ts) {
					@include h3mob;
				}
			}

			.caption2 {
				display: flex;
				align-self: center;
				@media (max-width: $tm) {
					flex-direction: column;
				}
				.leftCaption {
					@include rt;
					color: $cred1;
					width: 33.33%;
					line-height: 88%;
					padding-right: 20px;
					@media (max-width: $ts) {
						width: unset;
						padding-right: 25px;
					}
				}

				.rightCaption {
					@include h4;
					width: 66.66%;
					padding-right: 35px;
					display: flex;
					align-items: flex-end;
					@media (max-width: $tm) {
						margin-top: 15px;
						width: unset;
					}
				}
			}

			.ic-icplus {
				position: absolute;
				top: 24px;
				right: 0px;
				font-size: 19px;
				transition: 0.3s ease;
			}
		}

		.accordbody {
			.bot {
				padding-bottom: 30px;
			}
			&::before,
			&::after {
				content: "";
				display: table;
			}

			&::after {
				clear: both;
			}
			img{
				margin-bottom: 30px;
			}
			img.left {
				width: 66.66%;
				float: left;
				margin-right: 30px;
				@media (max-width: $tl) {
					width: 60%;
				}
				@media (max-width: $tm) {
					width: calc(50% - 10px);
					margin-right: 20px;
				}
				@media (max-width: $ts) {
					float: unset;
					width: 100%;
					margin-right: 0;
				}
			}

			p {
				@include t3;
				@media (max-width: $ts) {
					@include t2;
				}
				margin-top: 0;
			}

			.threeCol {
				display: flex;
				justify-content: space-between;
				@media (max-width: $ts) {
					flex-direction: column;
				}
				& > div {
					width: calc(33.33% - 15px);
					@media (max-width: $ts) {
						width: 100%;
					}

					img {
						width: 100%;
						@media (max-width: $ts) {
							margin: 16px 0;
						}
					}

					p {
						margin-top: 0;
					}

					* + p {
						margin-top: 16px;
					}
				}
			}
		}

		&.open {
			.accordhead {
				.caption {
					color: $cred1;
					align-self: flex-end;
				}

				.ic-icplus {
					color: $cred1;
					transform: rotate(45deg);
				}
			}
		}
	}
}
</style>