<template>
	<div class="burger" :class="[cls]">
		<span class="ic-icburger2"></span>
		<span class="ic-icclose"></span>
	</div>
</template>

<script>
export default {
	name: "BurgerBtn",
	data() {
		return {
			cls: ''
		}
	},
	methods: {
		scroll() {
			if (window.scrollY > 55) {
				this.cls = 'tm';
			} else {
				this.cls = '';
			}
		},
	},
	mounted() {
		document.addEventListener("scroll", this.scroll);
	},
	destroyed () {
		document.removeEventListener("scroll", this.scroll);
	},
};
</script>

<style lang='scss'>
.burger {
	cursor: pointer;
	display: none;
	position: fixed;
	z-index: 50;
	top: 0;
	right: 0;
	height: 55px;
	width: 55px;
	background-color: $cred1;
	justify-content: center;
	align-items: center;
	color: $cwhite;
	font-size: 20px;
	transition: 0.3s ease;
	&.tm {
		top: 15px;
		right: 15px;
	}

	@media (max-width: $ts) {
		display: flex;
	}
	.ic-icburger2{
		opacity: 1;
		transform: none;
		transition: 0.3s ease;
	}
	.ic-icclose{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(1.5);
		filter: blur(50px);
		transition: 0.3s ease;
	}
}
.opened{
	top: 0 !important;
	right: 0 !important;
	.ic-icburger2{
		transform: scale(0.2);
		opacity: 0;
	}
	.ic-icclose{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(1);
		filter: blur(0px);
	}
}
</style>