<template>
	<router-view :key="$route.fullPath"></router-view>
</template>

<script>
import { Bus } from "@/main.js";
import { rtParams } from "@/utils.js";
export default {
	methods: {
		onLangChange(e) {
			if (e == "ru" && this.$i18n.locale != "ru") {
				this.$router.push({
					name: this.$route.name,
					params: this.ruLocaleParam,
				});
			} else if (e == "uk" && this.$i18n.locale != "uk") {
				this.$router.push({
					name: this.$route.name,
					params: this.uaLocaleParam,
				});
			} else if (e == "en" && this.$i18n.locale != "en") {
				this.$router.push({
					name: this.$route.name,
					params: this.enLocaleParam,
				});
			}
			this.loadData();
		},
		loadData() {
			this.axios
				.post("/api/menu/get-by-ids", {
					ids: [202, 215, 228],
					lang: this.$i18n.locale,
				})
				.then((response) => {
					response = response.data.data;
					this.$store.commit("setMenus", {
						mainMenu: response[202],
						footerMenu: response[215],
						productMenu: response[228],
					});
				});
			this.axios
				.post("/api/settings/all", {
					lang: this.$i18n.locale,
				})
				.then((response) => {
					response = response.data.data;
					this.$store.commit("setSettings", response);
				});
		},
	},
	computed: {
		...rtParams,
	},
	created() {
		Bus.$on("langchange", this.onLangChange);
		this.loadData();
	},
};
</script>

<style lang="scss">
@import "./scss/main.scss";
@import '@/scss/icofont.scss';
body{
	overflow-y: scroll;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.8s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
.fadefast-enter-active,
.fadefast-leave-active {
	transition: opacity 0.2s;
}
.fadefast-enter,
.fadefast-leave-to {
	opacity: 0;
}
</style>
