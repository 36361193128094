<template>
	<section
		class="fullWIdthImage"
		:style="{
			backgroundImage: `url(${path(d.image)}`,
		}"
	>
		<div class="container">
			<router-link :to="d.link">
				{{d.title}}
				<span class="ic-icarrow-right"></span>
			</router-link>
		</div>
	</section>
</template>

<script>
export default {
	name: "FullWidthImgSection",
};
</script>

<style lang="scss">
.fullWIdthImage {
	background-size: cover;
	background-position: center;

	.container {
		@include container;
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		height: 600px;

		a {
			width: 25%;
			flex-shrink: 0;
			min-height: 148px;
			padding: 20px 20px 50px 20px;
			background-color: $cred1;
			position: relative;

			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 120%;
			color: $cwhite;
			text-decoration: none;

			@media (max-width: $ts) {
				width: 50%;
			}
			@media (max-width: $m) {
				width: 100%;
			}

			.ic-icarrow-right {
				font-size: 24px;
				position: absolute;
				bottom: 20px;
				right: 20px;
			}
		}
	}
}
</style>