<template>
	<section class="infoSlider" :class="theme">
		<div class="container">
			<div class="secWrap">
				<Flickity class="infoSliderFl" :options="flickityOptions" ref="flikity">
					<div class="slide" v-for="(item, index) in d.list" :key="index">
						<div class="left">
							<h2>{{ item.title }}</h2>
							<div v-html="item.text" class="ArticleTxt"></div>
							<div class="wrp">
								<router-link v-if="item.link" :to="item.link" class="button2"
									>{{ $t("inDetail") }} <span class="ic-icarrow-right"></span
								></router-link>
							</div>
						</div>
						<div class="right">
							<div class="preview" @click="onYtCkick(item.video)">
								<img :src="path(item.image)" :alt="path(item.image)" />
								<div class="indicator">
									<div
										:style="{
											width: `${currentValue}%`,
											opacity: isTouched ? 0 : 1,
										}"
									></div>
								</div>
								<div v-if="item.video" class="playIco">
									<span class="ic-icplay"></span>
								</div>
							</div>
							<div class="txt">{{ item.video_length }}</div>
						</div>
					</div>
				</Flickity>
				<div class="sliderControlls" :class="theme">
					<div class="toprew" @click="onNext">
						<span class="ic-icarrow-left"></span>
					</div>
					<div class="toright" @click="onPrew">
						<span class="ic-icarrow-right"></span>
					</div>
					<div class="counter">
						<div>
							{{ currentIndex + 1 }} / <span> {{ d.list.length }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<yt-modal
			v-if="ytModal"
			:videoId="videoId"
			@close="ytModal = !ytModal"
		></yt-modal>
	</section>
</template>

<script>
import Flickity from "vue-flickity";
import YtModal from "../Parts/YtModal.vue";
export default {
	name: "InfoSlider",
	components: {
		Flickity,
		YtModal,
	},
	props: ["theme"],
	data() {
		return {
			flickityOptions: {
				prevNextButtons: false,
				pageDots: false,
				contain: true,
				cellAlign: "right",
				groupCells: true,
				autoPlay: 10000,
				pauseAutoPlayOnHover: false,
				adaptiveHeight: true,

				draggable: false,
				selectedAttraction: 1.1,
				friction: 1.1,
			},
			currentIndex: 0,

			duration: 10000,
			start: null,
			currentTime: 0,
			currentValue: 0,
			timerId: null,
			isTouched: false,

			ytModal: false,
			videoId: null,
		};
	},
	methods: {
		onNext() {
			this.isTouched = true;
			this.$refs.flikity.previous(false, true);
		},
		onPrew() {
			this.isTouched = true;
			this.$refs.flikity.next(false, true);
		},
		onYtCkick(url) {
			if (url) {
				this.videoId = url;
				this.ytModal = true;
			}
		},
	},
	mounted() {
		this.$refs.flikity.on("change", (i) => {
			this.currentIndex = i;
			this.start = new Date();
		});
		this.$refs.flikity.on("dragStart", () => {
			this.isTouched = true;
		});
		this.start = new Date();

		this.timerId = setInterval(() => {
			if (this.isTouched) window.clearInterval(this.timerId);
			let d1 = new Date();
			let diff = d1 - this.start;
			this.currentValue = (diff * 100) / this.duration;
		}, 10);
	},
};
</script>

<style lang="scss">
.infoSlider {
	padding: 60px 0;
	background-color: $cgraybg1;

	.container {
		@include container;

		.secWrap {
			position: relative;
		}

		.slide {
			display: grid;
			grid-template: 1fr / 25% 1fr;
			width: 100%;
			margin-right: 20px;

			@media (max-width: $tl) {
				display: block;
			}

			.left {
				flex-shrink: 0;
				// width: 25%;
				padding-right: 50px;
				display: flex;
				flex-direction: column;

				@media (max-width: $tl) {
					padding-right: 0;
				}

				h2 {
					@include h2l;
					margin: 0 0 30px;
					color: $cwhite;

					@media (max-width: $ts) {
						@include h2mob;
						color: $cwhite;
					}
				}

				p {
					@include t2l;
					color: $cwhite;
					margin: 0;

					& + p {
						margin-top: 16px;
					}
				}

				.wrp {
					flex-grow: 1;
					display: flex;
					justify-content: flex-end;
					flex-direction: column;
					margin-top: 30px;
				}
			}

			.right {
				flex-shrink: 0;

				// width: 75%;
				@media (max-width: $tl) {
					margin-top: 30px;
				}

				.preview {
					position: relative;
					cursor: pointer;

					img {
						width: 100%;
						height: 564px;
						object-fit: cover;
						z-index: 1;
						display: block;
						@media (max-width: $ts) {
							height: 400px;
						}
					}

					.indicator {
						width: 100%;
						height: 3px;
						background: rgba(0, 0, 0, 0.4);
						position: relative;

						div {
							background-color: $cgray7;
							position: absolute;
							top: 0;
							left: 0;
							height: 3px;
							transition: opacity 0.3s ease;
						}
					}

					.playIco {
						position: absolute;
						z-index: 5;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						border-radius: 50%;
						height: 70px;
						width: 70px;
						background-color: $cwhite;
						display: flex;
						justify-content: center;
						align-items: center;
						transition: 0.3s ease;

						.ic-icplay {
							transition: 0.3s ease;
							font-size: 20px;
						}
					}

					&:hover {
						.playIco {
							background-color: $cred2;

							.ic-icplay {
								color: $cwhite;
							}
						}
					}
				}

				.txt {
					padding: 5px 5px 72px;
					@include t4;
					color: $cgray5;

					@media (max-width: $tl) {
						padding: 5px 5px 10px;
					}
				}
			}
		}
		.sliderControlls {
			position: absolute;
			bottom: 0;
			right: 0px;
			width: 75%;

			@media (max-width: $tl) {
				position: static;
				width: 100%;
			}
		}
	}

	&.light,
	&.gray {
		&.light {
			background-color: $cwhite;
		}
		&.gray {
			background-color: $cgray6;
		}
		.container {
			.slide {
				.left {
					h2 {
						color: $cblacktxt;

						@media (max-width: $ts) {
							color: $cblacktxt;
						}
					}

					p {
						color: $cblacktxt;
					}
				}

				.right {
					.txt {
						color: $cblacktxt;
					}
				}
			}
		}
	}
	.flickity-viewport {
		transition: height 0.2s;
	}
}
</style>