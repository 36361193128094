import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import SlideUpDown from 'vue-slide-up-down'
import i18n from './i18n'
import Vuelidate from 'vuelidate'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueYouTubeEmbed from 'vue-youtube-embed'
// Vue.use(VueYouTubeEmbed);
Vue.use(Vuelidate)
Vue.use(VueAxios, axios)

import {
    utils
} from '@/utils.js';
Vue.mixin(utils);

router.beforeEach((to, from, next) => {
    i18n.locale = to.params.locale || 'uk';

    if (to.path.length > 1 && to.path[to.path.length - 1] == '/') {
        next({
            path: to.path.substring(0, to.path.length - 1)
        });
    } else next();
});

let Bus = new Vue();
export {
    Bus
};

Vue.config.productionTip = false
Vue.component('slide-up-down', SlideUpDown)
new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
