<template>
	<section class="TextLeftCol" :class="theme">
		<div class="container">
			<div class="title">
				<h2>{{ d.title }}</h2>
			</div>
			<div class="rightSide">
				<div v-if="textprop == 'text-3-column'" class="trheeCol">
					<div v-html="d.text" class="ArticleTxt"></div>
					<!-- <p class="moreBlk">
						<a href="#" class="moreLnk"
							>Детально <span class="decoLine1"></span
						></a>
					</p> -->
				</div>
				<div v-else-if="textprop == 'text-and-image-2'" class="smollRightImg">
					<img
						:src="path(d.image)"
						:alt="path(d.image)"
						:class="[d.img_size, d.img_position]"
					/>
					<div v-html="d.text" class="ArticleTxt"></div>
					<!-- <p class="moreBlk">
						<a href="#" class="moreLnk"
							>Детально <span class="decoLine1"></span
						></a>
					</p> -->
				</div>
				<div v-else-if="textprop == 'text-element'" class="singleColTxt">
					<div v-html="d.text" class="ArticleTxt"></div>
				</div>
				<!-- <div v-else-if="textprop == 'simple-text'" class="text2fr">
					<h2>
						Biopharma використовує лише плазму від кваліфікованих донорів.
					</h2>
					<p>
						Biopharma також не використовує плазму від спорадичних або
						випадкових пожертвувань. Тому ми просимо наших донорів плазми
						зобов’язатися робити регулярні пожертви.
					</p>
					<p>
						Biopharma також не використовує плазму від спорадичних або
						випадкових пожертвувань. Тому ми просимо наших донорів плазми
						зобов’язатися робити регулярні пожертви.
					</p>
					<p>
						Biopharma також не використовує плазму від спорадичних або
						випадкових пожертвувань. Тому ми просимо наших донорів плазми
						зобов’язатися робити регулярні пожертви.
					</p>
					<p class="moreBlk">
						<a href="#" class="moreLnk"
							>Детально <span class="decoLine1"></span
						></a>
					</p>
				</div> -->
				<!-- <div class="bigLeftImg">
					<img :src="require('@/assets/img/bigleftimg.png')" alt="123" />
					<div>
						<h2>
							Biopharma використовує лише плазму від кваліфікованих донорів.
						</h2>
						<p>
							Biopharma також не використовує плазму від спорадичних або
							випадкових пожертвувань. Тому ми просимо наших донорів плазми
							зобов’язатися робити регулярні пожертви.
						</p>
					</div>
					<p class="moreBlk">
						<a href="#" class="moreLnk"
							>Детально <span class="decoLine1"></span
						></a>
					</p>
				</div>
				<div class="smollLeftImg">
					<img :src="require('@/assets/img/smollleftImg.png')" alt="123" />
					<div>
						<h2>
							Biopharma використовує лише плазму від кваліфікованих донорів.
						</h2>
						<p>
							Biopharma також не використовує плазму від спорадичних або
							випадкових пожертвувань. Тому ми просимо наших донорів плазми
							зобов’язатися робити регулярні пожертви.
						</p>
						<p>
							Biopharma також не використовує плазму від спорадичних або
							випадкових пожертвувань. Тому ми просимо наших донорів плазми
							зобов’язатися робити регулярні пожертви.
						</p>
						<p>
							Biopharma також не використовує плазму від спорадичних або
							випадкових пожертвувань. Тому ми просимо наших донорів плазми
							зобов’язатися робити регулярні пожертви.
						</p>
					</div>
					<p class="moreBlk">
						<a href="#" class="moreLnk"
							>Детально <span class="decoLine1"></span
						></a>
					</p>
				</div>
				<div class="bigRightImg">
					<img :src="require('@/assets/img/bigleftimg.png')" alt="123" />
					<div>
						<h2>
							Biopharma використовує лише плазму від кваліфікованих донорів.
						</h2>
						<p>
							Biopharma також не використовує плазму від спорадичних або
							випадкових пожертвувань. Тому ми просимо наших донорів плазми
							зобов’язатися робити регулярні пожертви.
						</p>
					</div>
					<p class="moreBlk">
						<a href="#" class="moreLnk"
							>Детально <span class="decoLine1"></span
						></a>
					</p>
				</div>
				<div class="smollRightImg">
					<img :src="require('@/assets/img/smollleftImg.png')" alt="123" />
					<div>
						<h2>
							Biopharma використовує лише плазму від кваліфікованих донорів.
						</h2>
						<p>
							Biopharma також не використовує плазму від спорадичних або
							випадкових пожертвувань. Тому ми просимо наших донорів плазми
							зобов’язатися робити регулярні пожертви.
						</p>
						<p>
							Biopharma також не використовує плазму від спорадичних або
							випадкових пожертвувань. Тому ми просимо наших донорів плазми
							зобов’язатися робити регулярні пожертви.
						</p>
						<p>
							Biopharma також не використовує плазму від спорадичних або
							випадкових пожертвувань. Тому ми просимо наших донорів плазми
							зобов’язатися робити регулярні пожертви.
						</p>
					</div>
					<p class="moreBlk">
						<a href="#" class="moreLnk"
							>Детально <span class="decoLine1"></span
						></a>
					</p>
				</div>
				
				 -->
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "TextLeftCol",
	props: ["theme", "textprop"],
};
</script>

<style lang="scss">
.TextLeftCol {
	padding: 30px 0;
	.container {
		@include container;
		display: grid;
		grid-template: auto / 1fr 3fr;
		@media (max-width: $tl) {
			display: block;
		}
		.title {
			padding-right: 30px;
			flex-shrink: 0;

			h2 {
				@include h3mob;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}
		.rightSide {
			@media (max-width: $tl) {
				margin-top: 40px;
			}
			& > * + * {
				margin-top: 16px;
			}
			.moreBlk {
				margin-top: 40px;
			}
			h2 {
				@include h3;
				margin-top: 0;
			}
			p {
				@include t4;
				margin-top: 0;
			}
			img.left {
				float: left !important;
				margin-right: 20px;
			}
			img.right {
				float: right !important;
				margin-left: 20px;
			}
			img.big {
				width: 66.66% !important;
				@media (max-width: $ts) {
					float: unset !important;
					width: 100% !important;
					margin: 0 0 16px !important;
				}
			}
			img.smoll,
			img.small {
				width: 33.33% !important;
				@media (max-width: $ts) {
					float: unset !important;
					width: 100% !important;
					margin: 0 0 16px !important;
				}
			}
			.bigLeftImg {
				img {
					float: left;
					width: calc(66.66% - 20px);
					margin-right: 20px;
					@media (max-width: $ts) {
						float: unset;
						width: 100%;
						margin: 0 0 16px;
					}
				}
				&::before,
				&::after {
					content: "";
					display: table;
				}

				&::after {
					clear: both;
				}
			}
			.smollLeftImg {
				img {
					float: left;
					width: calc(33.33% - 20px);
					margin-right: 20px;
					@media (max-width: $ts) {
						float: unset;
						width: 100%;
						margin: 0 0 16px;
					}
				}
				&::before,
				&::after {
					content: "";
					display: table;
				}

				&::after {
					clear: both;
				}
			}
			.bigRightImg {
				img {
					// float: right;
					// width: calc(66.66% - 20px);
					// margin-left: 20px;
					@media (max-width: $ts) {
						float: unset;
						width: 100%;
						margin: 0 0 16px;
					}
				}
				&::before,
				&::after {
					content: "";
					display: table;
				}

				&::after {
					clear: both;
				}
			}
			.smollRightImg {
				img {
					// float: right;
					// width: calc(33.33% - 20px);

					@media (max-width: $ts) {
						float: unset;
						width: 100%;
						margin: 0 0 16px;
					}
				}
				&::before,
				&::after {
					content: "";
					display: table;
				}

				&::after {
					clear: both;
				}
			}
			.text2fr {
				width: 66.66%;
				@media (max-width: $tm) {
					width: 100%;
				}
			}
			.trheeCol {
				column-count: 3;
				column-gap: 30px;
				@media (max-width: $tl) {
					column-count: 2;
				}
				@media (max-width: $ts) {
					column-count: 1;
				}
			}
			.singleColTxt {
				p {
					@include t3l;
				}
			}
		}
	}
	&.gray {
		background-color: $cgray6;
	}
}
</style>