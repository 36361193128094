import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '../layout/MainLayout.vue'
import MainPage from '@/views/MainPage.vue'

Vue.use(VueRouter)

const routes = [{
    path: '',
    // name: 'MainLayout',
    component: MainLayout,
    children: [{
            path: '/:locale(ru|uk|en)?',
            name: 'MainPage',
            component: MainPage
        },
        {
            path: '/:locale(ru|uk|en)?/news',
            name: 'news',
            component: () => import( /* webpackChunkName: "news" */ '@/views/News.vue'),
        },
        {
            path: '/:locale(ru|uk|en)?/news/:category',
            name: 'newscategory',
            component: () => import( /* webpackChunkName: "news" */ '@/views/News.vue'),
        },
        {
            path: '/:locale(ru|uk|en)?/news/:category/:slug',
            name: 'article',
            component: () => import( /* webpackChunkName: "article" */ '@/views/Article.vue'),
        },
        {
            path: '/:locale(ru|uk|en)?/products',
            name: 'products',
            component: () => import( /* webpackChunkName: "Products" */ '@/views/Products.vue'),
        },
        {
            path: '/:locale(ru|uk|en)?/products/:category',
            name: 'productscategory',
            component: () => import( /* webpackChunkName: "Products" */ '@/views/Products.vue'),
        },
        {
            path: '/:locale(ru|uk|en)?/products/:category/:slug',
            name: 'product',
            component: () => import( /* webpackChunkName: "ProductItem" */ '@/views/Product.vue'),
        },


        {
            path: '/:locale(ru|uk|en)?/farmakonaglyad/specialists',
            name: 'specialistsform',
            component: () => import( /* webpackChunkName: "form" */ '@/views/Form1.vue'),
        },
        {
            path: '/:locale(ru|uk|en)?/farmakonaglyad/consumers',
            name: 'consumersform',
            component: () => import( /* webpackChunkName: "form" */ '@/views/Form2.vue'),
        },
        {
            path: '/:locale(ru|uk|en)?/search',
            name: 'search',
            component: () => import( /* webpackChunkName: "search" */ '@/views/Search.vue'),
        },

        {
            path: '/:locale(ru|uk|en)?/:slug',
            name: 'landing',
            component: () => import( /* webpackChunkName: "landing" */ '@/views/LandingPage.vue'),
        },
        {
            path: '/:locale(ru|uk|en)?/kontakty/:slug',
            name: 'kontakti',
            component: () => import( /* webpackChunkName: "ContactsPage" */ '@/views/ContactsPage.vue'),
        },
        {
            path: '*',
            name: 'Page404',
            component: () => import( /* webpackChunkName: "Page404" */ '@/views/Page404.vue'),
        },
    ]
}, ]

const router = new VueRouter({
    mode: 'history',
    // base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) return savedPosition

        return {
            x: 0,
            y: 0
        }
    },
    routes
})

export default router
