<template>
	<div class="fe">
		<default-input
			v-if="inp"
			:placeholder="$store.state.footer_form_placeholder"
			@change="onFieldChange"
			propname="email"
			:validate="$v.email"
			:showError="showError"
			mode="button"
			@submit="formSend"
		></default-input>
		<transition name="fade">
			<div v-if="modalShown" class="success">
				<div><span class="ic-check"></span> {{ $t("ready") }}</div>
			</div>
		</transition>
	</div>
</template>

<script>
import DefaultInput from "./DefaultInput.vue";
import { required, email } from "vuelidate/lib/validators";
export default {
	components: { DefaultInput },
	name: "FooterSubscribeForm",
	data() {
		return {
			showError: false,
			inp: true,

			email: "",

			sending: false,
			modalShown: false,
		};
	},
	validations() {
		return {
			email: {
				required,
				email,
			},
		};
	},
	methods: {
		formSend() {
			this.showError = true;
			this.axios
				.post("/api/news/subscribe", {
					email: this.email,
				})
				.then((response) => {
					console.log(response);
					this.modalShown = true;
					setTimeout(() => {
						this.modalShown = false;
						this.showError = false;
						this.email = "";
						this.inp = false;
						setTimeout(() => {
							this.inp = true;
						}, 500);
					}, 3000);
				});
		},
	},
};
</script>

<style lang="scss">
.fe {
	height: 60px;
	position: relative;
	label.inputComponent {
		border: 1px solid #7f8192 !important;
	}
	.success {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: 10;
		background-color: $csuccess;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		div {
			display: flex;
			align-items: center;
			.ic-check {
				font-size: 28px;
				margin-right: 10px;
			}
		}
	}
}
</style>