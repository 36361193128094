<template>
	<div class="mainWrap">
		<burger-btn
			@click.native="menuShown = !menuShown"
			:class="{ opened: menuShown }"
		></burger-btn>
		<mobile-menu
			v-if="menuShown && isMobile"
			@close="menuShown = false"
		></mobile-menu>
		<vue-header></vue-header>
		<router-view :key="$route.fullPath"></router-view>
		<vue-footer></vue-footer>
	</div>
</template>
<script>
import header from "@/components/Parts/Header";
import footer from "@/components/Parts/Footer";
import BurgerBtn from "../components/Parts/BurgerBtn.vue";
import MobileMenu from "../components/Parts/MobileMenu.vue";
export default {
	name: "MainLayout",
	components: {
		vueHeader: header,
		vueFooter: footer,
		BurgerBtn,
		MobileMenu,
	},
	data() {
		return {
			menuShown: false,
			isMobile: false,
		};
	},
	methods: {
		setIsMobile() {
			if (window.innerWidth > 600) this.isMobile = false;
			else this.isMobile = true;
		},
	},
	created() {
		this.setIsMobile();
		window.addEventListener("resize", this.setIsMobile);
	},
};
</script>

<style lang="scss">
main{
  min-height: 100vh;
}
</style>