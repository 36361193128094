import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        doctorAlert: true,

        mainMenu: null,
        footerMenu: null,
        productMenu: null,

        news_title: null,
        news_description: null,
        news_per_page: null,
        sing_link_title: null,
        sing_link: null,
        footer_form_title: null,
        footer_form_placeholder: null,
        footer_link_text: null,
        footer_link_href: null,
        copyright: null,
        links: null,

        form_pharmakanyaglyad_default_description: null,
        form_pharmakanyaglyad_default_text1: null,
        form_pharmakanyaglyad_default_text2: null,
        form_pharmakanyaglyad_default_text3: null,
        form_pharmakanyaglyad_default_title: null,
        form_pharmakanyaglyad_description: null,
        form_pharmakanyaglyad_text1: null,
        form_pharmakanyaglyad_text2: null,
        form_pharmakanyaglyad_text3: null,
        form_pharmakanyaglyad_title: null,
        form_products_text1: null,
        form_products_text2: null,
        products_description: null,
        products_per_page: null,
        products_title: null
    },
    mutations: {
        setMenus(state, payload) {
            state.mainMenu = payload.mainMenu.items;
            state.footerMenu = payload.footerMenu.items;
            state.productMenu = payload.productMenu.items;
        },
        setSettings(state, payload) {
            state.news_title = payload.news_title;
            state.news_description = payload.news_description;
            state.news_per_page = payload.news_per_page;
            state.sing_link_title = payload.sing_link_title;
            state.sing_link = payload.sing_link;
            state.footer_form_title = payload.footer_form_title;
            state.footer_form_placeholder = payload.footer_form_placeholder;
            state.footer_link_text = payload.footer_link_text;
            state.footer_link_href = payload.footer_link_href;
            state.copyright = payload.copyright;
            state.links = payload.links;

            state.form_pharmakanyaglyad_default_description =
                payload.form_pharmakanyaglyad_default_description;
            state.form_pharmakanyaglyad_default_text1 =
                payload.form_pharmakanyaglyad_default_text1;
            state.form_pharmakanyaglyad_default_text2 =
                payload.form_pharmakanyaglyad_default_text2;
            state.form_pharmakanyaglyad_default_text3 =
                payload.form_pharmakanyaglyad_default_text3;
            state.form_pharmakanyaglyad_default_title =
                payload.form_pharmakanyaglyad_default_title;
            state.form_pharmakanyaglyad_description =
                payload.form_pharmakanyaglyad_description;
            state.form_pharmakanyaglyad_text1 =
                payload.form_pharmakanyaglyad_text1;
            state.form_pharmakanyaglyad_text2 =
                payload.form_pharmakanyaglyad_text2;
            state.form_pharmakanyaglyad_text3 =
                payload.form_pharmakanyaglyad_text3;
            state.form_pharmakanyaglyad_title =
                payload.form_pharmakanyaglyad_title;
            state.form_products_text1 = payload.form_products_text1;
            state.form_products_text2 = payload.form_products_text2;
            state.products_description = payload.products_description;
            state.products_per_page = payload.products_per_page;
            state.products_title = payload.products_title;
        }
    },
    actions: {
        hideDoctor({ state }) {
            state.doctorAlert = false;
        }
    },
    getters: {
        mainMenu: state => {
            return state.mainMenu;
        }
    },
    modules: {}
});
