<template>
    <header>
        <div class="container">
            <div class="logoLingWrp">
                <router-link
                    :to="{
                        name: 'MainPage',
                        params: {
                            locale: $i18n.locale == 'uk' ? null : $i18n.locale
                        }
                    }"
                    class="logoLink"
                >
                    <img :src="require(`@/assets/img/logo.svg`)" alt="logo" />
                </router-link>
            </div>

            <div class="hdRightTop">
                <router-link
                    :to="{
                        name: 'search',
                        params: { locale: locale == 'uk' ? null : locale }
                    }"
                    class="searchWrp"
                >
                    <span class="ic-icsearch"></span>
                </router-link>
                <combo-box :items="langs"></combo-box>
                <div class="signWrp">
                    <a
                        :href="$store.state.sing_link"
                        class="button1"
                        target="_blank"
                    >
                        <span class="t">{{
                            $store.state.sing_link_title
                        }}</span>
                        <span class="ic-icarrow-right"></span>
                    </a>
                </div>
            </div>
            <Flickity
                v-if="mainMenu"
                class="hdRightBot"
                :options="flickityOptions"
                ref="menuSlider"
            >
                <template v-for="(item, idx) in mainMenu">
                    <router-link
                        v-if="!item.children"
                        :key="idx"
                        :to="item.url"
                        class="lnk"
                        ><span
                            >{{ item.name }}
                            <span class="decoLine1"></span></span
                    ></router-link>
                    <div v-else :key="idx" class="dropdown">
                        <router-link
                            :to="item.url"
                            class="lnk"
                            @mouseenter.native="onDDenter($event, idx)"
                            @mouseleave.native="onDDleave(idx)"
                            ><span
                                >{{ item.name }}
                                <span class="decoLine1"></span></span
                        ></router-link>
                        <span class="ic-icexpand-down"></span>
                    </div>
                </template>
            </Flickity>
            <div
                v-if="ddShown"
                class="dd"
                :style="ddPos"
                @mouseenter="onDDenter($event)"
                @mouseleave="onDDleave()"
            >
                <router-link
                    v-for="(item, index) in curChildren"
                    :key="index"
                    :to="item.url"
                    @click.native="ddShown = false"
                    >{{ item.name }}</router-link
                >
            </div>
        </div>
    </header>
</template>

<script>
import Flickity from "vue-flickity";
import ComboBox from "./ComboBox.vue";
// import { Bus } from "@/main.js";
import { mapGetters } from "vuex";
export default {
    name: "Header",
    components: {
        Flickity,
        ComboBox
    },
    data() {
        return {
            flickityOptions: {
                // prevNextButtons: false,
                pageDots: false,
                contain: true,
                cellAlign: "right",
                groupCells: true
            },
            curChildren: null,
            ddParent: null,
            ddShown: false,
            timer: null
        };
    },
    methods: {
        onDDenter(e, idx) {
            if (idx) {
                this.curChildren = this.$store.state.mainMenu[idx].children;
                this.ddParent = e.target;
            }
            window.clearTimeout(this.timer);
            this.ddShown = true;
        },
        onDDleave() {
            this.timer = setTimeout(() => {
                this.ddShown = false;
            }, 50);
        }
    },
    watch: {
        mainMenu() {
            setTimeout(() => {
                if (this.$refs["menuSlider"]) this.$refs["menuSlider"].resize();
            }, 500);
        }
    },
    computed: {
        ...mapGetters(["mainMenu"]),
        langs() {
            return [
                { title: "Укр", lang: "uk" },
                { title: "En", lang: "en" },
                // { title: "Рус", lang: "ru" }
            ];
        },
        ddPos() {
            if (this.ddParent) {
                let t =
                    this.ddParent.getBoundingClientRect().height +
                    this.ddParent.getBoundingClientRect().top +
                    window.scrollY;
                let l = this.ddParent.getBoundingClientRect().left;
                return {
                    left: `${l}px`,
                    top: `${t}px`
                };
            }
            return null;
        }
    },
    mounted() {}
};
</script>

<style lang="scss">
.dd {
    position: fixed;
    background-color: #fff;
    z-index: 100;
    a {
        padding: 10px 20px;
        @include t4;
        display: block;
        text-decoration: none;
        &:hover {
            background-color: $cgray6;
        }
        & + a {
            border-top: 1px solid $cgray5;
        }
    }
}
header {
    .container {
        @include container;

        display: grid;
        grid-template: 1fr 1fr / 25% 1fr;
        @media (max-width: $ts) {
            grid-template: 1fr / 1fr 1fr;
        }

        .logoLingWrp {
            display: flex;
            align-items: center;
            grid-row: span 2;
            img {
                width: 284px;
                max-height: 41px;
                @media (max-width: $tl) {
                    width: 192px;
                }
                @media (max-width: $m) {
                    width: 127px;
                }
            }
            @media (max-width: $tm) {
                grid-row: unset;
            }
        }

        .hdRightTop {
            min-height: 55px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @media (max-width: $ts) {
                padding-right: 25px;
            }
            @media (max-width: $m) {
                padding-right: 40px;
            }

            .searchWrp {
                height: 15px;
                padding: 0 15px;
                text-decoration: none;
                .ic-icsearch {
                    font-size: 19px;
                    color: #595b69;
                }
            }

            .signWrp {
                @media (max-width: $ts) {
                    display: none;
                }
            }
        }

        .hdRightBot {
            // display: flex;
            // justify-content: flex-end;
            .flickity-button {
                padding: 0;
                background-color: $cgray7;
                // background-color: red;
                transform: unset;
                border-radius: 0;
                width: 40px;
                height: unset;
                &:disabled {
                    opacity: 0;
                }
                .flickity-button-icon {
                    width: 15px;
                    left: unset;
                    right: unset;
                    top: unset;
                    bottom: unset;
                    position: static;
                }
            }
            .previous {
                left: 0;
                bottom: 0;
                top: 0;
            }
            .next {
                right: 0;
                bottom: 0;
                top: 0;
            }
            @media (max-width: $tm) {
                grid-column: span 2;
            }
            @media (max-width: $ts) {
                display: none;
            }
            .lnk {
                min-height: 57px;
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 120%;
                letter-spacing: 0.075em;
                text-transform: uppercase;
                color: #111325;
                text-decoration: none;
                padding: 0 10px;
                display: flex;
                align-items: center;
                justify-content: center;

                & > span {
                    position: relative;

                    .decoLine1 {
                        bottom: -7px;
                        background: transparent;
                    }
                }

                &:hover > span .decoLine1::before {
                    pointer-events: none;
                    left: 0;
                }
            }

            .dropdown {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 10px;
                position: relative;

                .lnk {
                    padding: 0;
                    * {
                        pointer-events: none;
                    }
                }
            }
        }
    }
}
</style>
