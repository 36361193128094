<template>
	<section class="advantagesSection1">
		<div class="container">
			<div class="title">
				<h2>{{ d.title }}</h2>
			</div>
			<div class="advantagesWrap1">
				<div class="wrp">
					<template v-for="(item, index) in d.list">
						<a
							v-if="item.link"
							:key="index"
							class="chapterItem2"
							@click.prevent="linkClick(item.link)"
							:href="item.link"
						>
							<div class="caption">{{ item.title }}</div>
							<div class="sub" v-html="item.text"></div>
							<span class="ic-icarrow-right"></span>
						</a>
						<div
							v-else
							:key="index"
							class="chapterItem2"
						>
							<div class="caption">{{ item.title }}</div>
							<div class="sub" v-html="item.text"></div>
							<!-- <span class="ic-icarrow-right"></span> -->
						</div>
					</template>
				</div>
				<div
					class="bg"
					:style="{
						backgroundImage: `url(${path(d.image)}`,
					}"
				></div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "AdvantagesSection1",
};
</script>

<style lang="scss">
.advantagesSection1 {
	padding: 60px 0;
	background-color: $cgray6;

	// @media (max-width: $tm) {
	// 	padding-bottom: 0;
	// }

	.container {
		@include container;
		display: grid;
		grid-template: 1fr / 25% 1fr;

		@media (max-width: $tl) {
			display: block;
		}

		.title {
			// width: 25%;
			padding-right: 30px;

			h2 {
				@include h2;
				margin: 0;

				@media (max-width: $ts) {
					@include h2mob;
				}
			}
		}

		.advantagesWrap1 {
			// width: 75%;
			display: flex;

			@media (max-width: $tl) {
				margin-top: 30px;
			}

			@media (max-width: $tm) {
				flex-direction: column;
			}

			.wrp {
				width: 66.66%;
				// display: flex;
				// flex-wrap: wrap;
				display: grid;
				grid-template: 1fr / 1fr 1fr;
				grid-gap: 2px;
				a {
					cursor: pointer;
				}

				@media (max-width: $tm) {
					width: 100%;
				}

				@media (max-width: $ts) {
					grid-template: 1fr / 1fr;
				}

				.chapterItem2 {
					// width: 50%;
					min-height: 300px;

					@media (max-width: $ts) {
						min-height: unset;
					}
				}
			}

			.bg {
				width: 33.33%;
				background-position: center;
				background-size: cover;

				@media (max-width: $tm) {
					// width: 100%;
					// height: 742px;
					display: none;
				}
			}
		}
	}
}
</style>