<template>
    <section class="homeFirst">
        <div
            class="topImg"
            :style="{
                backgroundImage: `url(${path(bgImage)}`
            }"
        >
            <div class="container">
                <div class="headerwrp">
                    <h1>{{ d.title }}</h1>
                    <p class="sub">{{ d.subtitle }}</p>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="left">
                <span class="ic-landQuot"></span>
                <p>
                    {{ d.quote }}
                </p>
            </div>
            <template v-if="d.link">
                <a @click.prevent="modalShown = true" class="right">
                    <div class="txt">
                        <div class="t">{{ d.fio }}</div>
                        <div class="t2">{{ d.position }}</div>
                        <span class="ic-icarrow-right"></span>
                    </div>
                    <img :src="path(d.photo)" alt="123" />
                </a>
                <yt-modal
                    v-if="modalShown"
                    :videoId="videoId"
                    @close="modalShown = !modalShown"
                ></yt-modal>
            </template>
            <div v-else class="right">
                <div class="txt">
                    <div class="t">{{ d.fio }}</div>
                    <div class="t2">{{ d.position }}</div>
                </div>
                <img :src="path(d.photo)" alt="123" />
            </div>
        </div>
    </section>
</template>

<script>
import YtModal from "../Parts/YtModal.vue";
export default {
    name: "FirstScreen",
    components: {
        YtModal
    },
    data() {
        return { modalShown: false, width: 0 };
    },
    computed: {
        videoId() {
            return this.d.link.replace(/(.*?)watch\?v=/, "");
        },
        bgImage() {
            if (this.width > 768) return this.d.image;
            else return this.d.image_mob;
        }
    },
    created() {
        this.getW();
        window.addEventListener("resize", this.getW);
    },
    destroyed() {
        window.removeEventListener("resize", this.getW);
    },
    methods: {
        getW() {
            this.width = window.innerWidth;
        }
    }
};
</script>

<style lang="scss">
.homeFirst {
    .topImg {
        background-position: center;
        background-size: cover;

        .container {
            @include container;
            height: 500px;
            display: flex;
            align-items: flex-end;
            @media (max-width: $ts) {
              height: 320px;
            }

            .headerwrp {
                background-color: $cwhite;
                padding: 30px;
                width: 50%;
                box-sizing: border-box;

                @media (max-width: $tl) {
                    width: 66.66%;
                }

                @media (max-width: $ts) {
                    width: 100%;
                    margin-bottom: 15px;
                }

                h1 {
                    @include h1;
                    margin: 0;
                    color: $cred1;

                    @media (max-width: $ts) {
                        @include h1mob;
                        color: $cred1;
                    }
                }

                .sub {
                    @include t2;
                    margin: 2px 0 0;
                }
            }
        }
    }

    & > .container {
        @include container;
        display: flex;
        flex-wrap: wrap;

        .left {
            width: 50%;
            padding: 20px;
            margin: 30px 0;
            position: relative;

            @media (max-width: $tl) {
                width: 33.33%;
            }

            @media (max-width: $ts) {
                width: 100%;
                margin: 20px 0 0;
            }
            .ic-landQuot {
                position: absolute;
                top: 25px;
                left: 25px;
                font-size: 20px;
                color: $cgray4;
            }
            p {
                padding-left: 41px;
                @include t1;
                margin: 0;

                @media (max-width: $tl) {
                    @include t2;
                }

                span {
                    color: $cred1;
                }
            }
        }

        .right {
            width: 50%;
            margin: 30px 0;
            background-color: $cgray7;
            display: flex;
            align-items: flex-start;
            color: $cblacktxt;
            text-decoration: none;

            @media (max-width: $tl) {
                width: 66.66%;
            }

            @media (max-width: $ts) {
                width: 100%;
                margin-top: 0;
                background-color: $cwhite;
            }

            .txt {
                width: 50%;
                padding: 20px;
                align-self: stretch;
                position: relative;

                @media (max-width: $ts) {
                    padding: 10px;
                }

                .t {
                    @include h3;
                    margin: 0;

                    @media (max-width: $m) {
                        @include h3mob;
                    }
                }

                .t2 {
                    @include t3;
                    margin: 15px 0 0;
                }

                .ic-icarrow-right {
                    position: absolute;
                    bottom: 20px;
                    left: 20px;
                    font-size: 18px;

                    @media (max-width: $ts) {
                        display: none;
                    }
                }
            }

            img {
                width: 50%;
            }
        }
        a.right {
            cursor: pointer;
            * {
                cursor: pointer;
            }
        }
    }
}
</style>
